import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { CurrentUserContext } from '../../context/currentUser';
import styles from './AuthLogs.module.scss';
import { API_CLEAR_LOGS, API_GET_LOGS } from '../../config';
import useFetch from '../../hooks/useFetch';

const AuthLogsComponent = () => {
  const [currentUserState] = useContext(CurrentUserContext);

  const [logs, setLogs] = useState<null | Array<any>>(null);
  const [activeLog, setActiveLog] = useState<null | number>(null);

  const [
    { isLoading: isLoadingLogs, response: responseLogs, error: errorLogs },
    doFetchLogs,
  ] = useFetch(API_GET_LOGS);

  const [
    {
      isLoading: isLoadingClearLogs,
      response: responseClearLogs,
      error: errorClearLogs,
    },
    doFetchClearLogs,
  ] = useFetch(API_CLEAR_LOGS);

  useEffect(() => {
    doFetchLogs({
      method: 'POST',
      data: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (responseClearLogs) {
      doFetchLogs({
        method: 'POST',
        data: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseClearLogs]);

  useEffect(() => {
    if (responseLogs) {
      setLogs(_.orderBy(responseLogs?.data, ['ttamp'], ['desc']));
    }
  }, [responseLogs]);

  if (currentUserState.currentUser === undefined) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className={styles.main}>
      {(isLoadingLogs || isLoadingClearLogs) && (
        <div className={styles.minHeight40}>
          <h4>wait...</h4>
        </div>
      )}
      {(errorLogs || errorClearLogs) && (
        <div>
          <h2>Error</h2>
        </div>
      )}
      {logs !== null && logs?.length === 0 && (
        <div>
          <h2>Empty log list</h2>
        </div>
      )}
      {logs !== null && logs?.length > 0 && (
        <div className={styles.tree}>
          <div className={styles.lineNotActive}>
            {logs.map((el: any, idx: number) => {
              if (activeLog !== null && activeLog === el?.ttamp) {
                return (
                  <div
                    className={styles.lineNotActive}
                    style={{
                      marginLeft: 20,
                    }}
                    key={el?.ttamp + '_' + idx}
                  >
                    <span
                      onClick={() => setActiveLog(null)}
                      className={styles.toggleHidden}
                    >
                      {'↑'}
                    </span>
                    {el?.type} ({moment.utc(el?.ttamp).fromNow()})
                    <pre>{JSON.stringify(el, null, 4)}</pre>
                  </div>
                );
              } else {
                return (
                  <div
                    className={styles.lineNotActive}
                    style={{
                      marginLeft: 20,
                    }}
                    onClick={() => setActiveLog(el?.ttamp)}
                    key={el?.ttamp + '__' + idx}
                  >
                    <span className={styles.toggleHidden}>{'↓'}</span>
                    {el?.type} ({moment.utc(el?.ttamp).fromNow()})
                    <div
                      className={styles.lineNotActive}
                      style={{
                        marginLeft: 20,
                      }}
                    />
                  </div>
                );
              }
            })}
          </div>
        </div>
      )}
      <div className={styles.overActions}>
        <div className={styles.left} />
        <div className={styles.right}>
          <span />
          <span
            className={styles.btnAction}
            onClick={() => {
              if (isLoadingClearLogs) {
                return;
              }
              doFetchClearLogs({
                method: 'POST',
                data: {},
              });
            }}
          >
            {errorClearLogs
              ? 'Error'
              : isLoadingClearLogs
              ? 'Wait...'
              : 'Clear log list'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AuthLogsComponent;
