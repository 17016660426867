import React, { useEffect, useState } from 'react';
import styles from './Summary.module.scss';
import { useSelector } from 'react-redux';
import useFetch from '../../hooks/useFetch';
import {
  API_TOOL_RETOOL_SUMMARY,
  API_TOOL_RETOOL_UPD_SUMMARY,
} from '../../config';
import SummaryModalComponent from './SummaryModal';

let countStatusReq: number = 0;

const SummaryComponent = (): JSX.Element => {
  const activeProvider = useSelector((state: any) => state.provider.provider);

  const [showTable, setShowTable] = useState<boolean>(false);
  const [summaryTable, setSummaryTable] = useState<any>(null);
  const [showSummaryTable, setShowSummaryTable] = useState<boolean>(false);

  const [
    {
      isLoading: isLoadingSummary,
      response: responseSummary,
      error: errorSummary,
    },
    doFetchSummary,
  ] = useFetch(API_TOOL_RETOOL_SUMMARY + '?provider=' + activeProvider);

  const [
    {
      isLoading: isLoadingUpdSummary,
      response: responseUpdSummary,
      error: errorUpdSummary,
    },
    doFetchUpdSummary,
  ] = useFetch(API_TOOL_RETOOL_UPD_SUMMARY + '?provider=' + activeProvider);

  useEffect(() => {
    // FIRST
  }, []);

  useEffect(() => {
    if (responseSummary) {
      //   console.log('responseSummary', responseSummary);
    }
  }, [responseSummary]);

  useEffect(() => {
    if (responseUpdSummary) {
      //   console.log('responseUpdSummary', responseUpdSummary);
    }
  }, [responseUpdSummary]);

  return (
    <div className={styles.main}>
      <div className={styles.textRight}>
        <h2>Database: retool. Table: summary</h2>
      </div>
      <div className={styles.textRight}>
        <span
          className={styles.testBlock}
          onClick={() => {
            doFetchSummary({
              method: 'POST',
              data: {},
            });
            setShowTable(true);
          }}
        >
          SHOW ACTUAL TABLE
        </span>
        <span
          className={styles.testBlock}
          onClick={() => {
            doFetchUpdSummary({
              method: 'POST',
              data: {},
            });
          }}
        >
          {isLoadingUpdSummary
            ? 'WAIT...'
            : errorUpdSummary
            ? 'ERROR'
            : responseUpdSummary
            ? 'SUCCESS'
            : 'UPDATE TABLE'}
        </span>
      </div>
      <br />
      <hr />
      <div className={styles.overTestBlock}>
        <div>
          <div className={styles.textRight}>
            <h3>Table fields:</h3>
          </div>
          <div>
            <table>
              <thead>
                <tr>
                  <td>Field</td>
                  <td>Source</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>id</td>
                  <td>primary key</td>
                </tr>
                <tr>
                  <td>symbol_english</td>
                  <td>ticker</td>
                </tr>
                <tr>
                  <td>price_current</td>
                  <td>realTime.price</td>
                </tr>
                <tr>
                  <td>stat_52_wl</td>
                  <td>statistics.stock_price_summary.fifty_two_week_low</td>
                </tr>
                <tr>
                  <td>stat_52_wh</td>
                  <td>statistics.stock_price_summary.fifty_two_week_high</td>
                </tr>
                <tr>
                  <td>stat_52_w_change</td>
                  <td>statistics.stock_price_summary.fifty_two_week_change</td>
                </tr>
                <tr>
                  <td>beta</td>
                  <td>statistics.stock_price_summary.beta</td>
                </tr>
                <tr>
                  <td>outstanding_shares</td>
                  <td>statistics.stock_statistics.shares_outstanding</td>
                </tr>
                <tr>
                  <td>float_shares</td>
                  <td>statistics.stock_statistics.float_shares</td>
                </tr>
                <tr>
                  <td>revenue</td>
                  <td>statistics.financials.return_on_equity_ttm</td>
                </tr>
                <tr>
                  <td>earnings</td>
                  <td>
                    statistics.financials.income_statement.net_income_to_common_ttm
                  </td>
                </tr>
                <tr>
                  <td>debt</td>
                  <td>statistics.financials.balance_sheet.total_debt_mrq</td>
                </tr>
                <tr>
                  <td>cash</td>
                  <td>statistics.financials.balance_sheet.total_cash_mrq</td>
                </tr>
                <tr>
                  <td>cash_flow_from_operations</td>
                  <td>
                    statistics.financials.cash_flow.operating_cash_flow_ttm
                  </td>
                </tr>
                <tr>
                  <td>free_cashflow</td>
                  <td>
                    statistics.financials.cash_flow.levered_free_cash_flow_ttm
                  </td>
                </tr>
                <tr>
                  <td>eps</td>
                  <td>
                    statistics.financials.income_statement.diluted_eps_ttm
                  </td>
                </tr>
                <tr>
                  <td>bv</td>
                  <td>
                    statistics.financials.balance_sheet.book_value_per_share_mrq
                  </td>
                </tr>
                <tr>
                  <td>cr</td>
                  <td>statistics.financials.balance_sheet.current_ratio_mrq</td>
                </tr>
                <tr>
                  <td>de</td>
                  <td>
                    statistics.financials.balance_sheet.total_debt_to_equity_mrq
                  </td>
                </tr>
                <tr>
                  <td>roe</td>
                  <td>statistics.financials.return_on_equity_ttm</td>
                </tr>
                <tr>
                  <td>roa</td>
                  <td>statistics.financials.return_on_assets_ttm</td>
                </tr>
                <tr>
                  <td>pe_trailing</td>
                  <td>statistics.valuations_metrics.trailing_pe</td>
                </tr>
                <tr>
                  <td>pe_forward</td>
                  <td>statistics.valuations_metrics.forward_pe</td>
                </tr>
                <tr>
                  <td>peg</td>
                  <td>statistics.valuations_metrics.peg_ratio</td>
                </tr>
                <tr>
                  <td>pbv</td>
                  <td>statistics.valuations_metrics.price_to_book_mrq</td>
                </tr>
                <tr>
                  <td>profit_margin</td>
                  <td>statistics.financials.profit_margin</td>
                </tr>
                <tr>
                  <td>operating_margin</td>
                  <td>statistics.financials.operating_margin</td>
                </tr>
                <tr>
                  <td>ps</td>
                  <td>statistics.valuations_metrics.price_to_sales_ttm</td>
                </tr>
                <tr>
                  <td>rps</td>
                  <td>
                    statistics.financials.income_statement.revenue_per_share_ttm
                  </td>
                </tr>
                <tr>
                  <td>dps_currency</td>
                  <td>
                    statistics.dividends_and_splits.trailing_annual_dividend_rate
                  </td>
                </tr>
                <tr>
                  <td>dps_percent</td>
                  <td>
                    statistics.dividends_and_splits.trailing_annual_dividend_yield
                  </td>
                </tr>
                <tr>
                  <td>payout</td>
                  <td>statistics.dividends_and_splits.payout_ratio</td>
                </tr>
                <tr>
                  <td>cash_per_share</td>
                  <td>
                    statistics.financials.balance_sheet.total_cash_per_share_mrq
                  </td>
                </tr>
                <tr>
                  <td>market_cap</td>
                  <td>statistics.valuations_metrics.market_capitalization</td>
                </tr>
                <tr>
                  <td>last_dividends_date</td>
                  <td>statistics.dividends_and_splits.dividend_date</td>
                </tr>
                <tr>
                  <td>last_dividends_ex_date</td>
                  <td>statistics.dividends_and_splits.ex_dividend_date</td>
                </tr>
                <tr>
                  <td>dps_5_y</td>
                  <td>
                    statistics.dividends_and_splits.5_year_average_dividend_yield
                  </td>
                </tr>
                <tr>
                  <td>price_target_median</td>
                  <td>priceTarget.median</td>
                </tr>
                <tr>
                  <td>forward_dps_percent</td>
                  <td>
                    statistics.dividends_and_splits.forward_annual_dividend_yield
                  </td>
                </tr>
                <tr>
                  <td>forward_dps_currency</td>
                  <td>
                    statistics.dividends_and_splits.forward_annual_dividend_rate
                  </td>
                </tr>
                <tr>
                  <td>mrq</td>
                  <td>statistics.financials.most_recent_quarter</td>
                </tr>
                <tr>
                  <td>gross_profit</td>
                  <td>
                    statistics.financials.income_statement.gross_profit_ttm
                  </td>
                </tr>
                <tr>
                  <td>insiders</td>
                  <td>statistics.stock_statistics.percent_held_by_insiders</td>
                </tr>
                <tr>
                  <td>institution</td>
                  <td>
                    statistics.stock_statistics.percent_held_by_institutions
                  </td>
                </tr>
                <tr>
                  <td>last_split_factor</td>
                  <td>statistics.dividends_and_splits.last_split_factor</td>
                </tr>
                <tr>
                  <td>last_split_date</td>
                  <td>statistics.dividends_and_splits.last_split_date</td>
                </tr>
                <tr>
                  <td>eps_estimate_0_date</td>
                  <td>earningsEstimate.0.date</td>
                </tr>
                <tr>
                  <td>eps_period_0</td>
                  <td>earningsEstimate.0.period</td>
                </tr>
                <tr>
                  <td>eps_number_of_analysts_0</td>
                  <td>earningsEstimate.0.number_of_analysts</td>
                </tr>
                <tr>
                  <td>eps_avg_estimate_0</td>
                  <td>earningsEstimate.0.avg_estimate</td>
                </tr>
                <tr>
                  <td>eps_year_ago_eps_0</td>
                  <td>earningsEstimate.0.year_ago_eps</td>
                </tr>
                <tr>
                  <td>eps_estimate_1_date</td>
                  <td>earningsEstimate.1.date</td>
                </tr>
                <tr>
                  <td>eps_period_1</td>
                  <td>earningsEstimate.1.period</td>
                </tr>
                <tr>
                  <td>eps_number_of_analysts_1</td>
                  <td>earningsEstimate.1.number_of_analysts</td>
                </tr>
                <tr>
                  <td>eps_avg_estimate_1</td>
                  <td>earningsEstimate.1.avg_estimate</td>
                </tr>
                <tr>
                  <td>eps_year_ago_eps_1</td>
                  <td>earningsEstimate.1.year_ago_eps</td>
                </tr>
                <tr>
                  <td>website</td>
                  <td>profile.website</td>
                </tr>
                <tr>
                  <td>description</td>
                  <td>profile.description</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div />
      </div>
      <div
        style={{
          display: showTable ? 'block' : 'none',
        }}
      >
        <SummaryModalComponent
          setShowTable={setShowTable}
          isLoadingSummary={isLoadingSummary}
          responseSummary={responseSummary}
          errorSummary={errorSummary}
        />
      </div>
    </div>
  );
};

export default SummaryComponent;
