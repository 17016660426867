import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { CurrentUserContext } from '../context/currentUser';
import styles from './Config.module.scss';
import useFetch from '../hooks/useFetch';
import { API_GET_ADMIN_CONFIG, API_SET_ADMIN_CONFIG } from '../config';
import { IKeyVal } from '../interfaces';

const ConfigPage = () => {
  const [currentUserState] = useContext(CurrentUserContext);

  const [localConfig, setLocalConfig] = useState<IKeyVal>({});
  const [tmpVersion, setTmpVersion] = useState<string>('');
  const [tmpAndroidVersion, setTmpAndroidVersion] = useState<string>('');

  const [
    {
      isLoading: isLoadingConfigs,
      response: responseConfigs,
      error: errorConfigs,
    },
    doFetchConfigs,
  ] = useFetch(API_GET_ADMIN_CONFIG);

  const [
    {
      isLoading: isLoadingUpdConfig,
      response: responseUpdConfig,
      error: errorUpdConfig,
    },
    doFetchUpdConfig,
  ] = useFetch(API_SET_ADMIN_CONFIG);

  useEffect(() => {
    doFetchConfigs({
      method: 'POST',
      data: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (responseUpdConfig) {
      doFetchConfigs({
        method: 'POST',
        data: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseUpdConfig]);

  useEffect(() => {
    if (responseConfigs) {
      const tmpConfig: IKeyVal = {};
      for (let i = 0; i < responseConfigs?.data?.length; i++) {
        if (responseConfigs?.data[i].key === 'supportedVersions') {
          tmpConfig[responseConfigs?.data[i].key] = JSON.parse(
            responseConfigs?.data[i].value
          );
          continue;
        }
        if (responseConfigs?.data[i].key === 'supportedAndroidVersions') {
          tmpConfig[responseConfigs?.data[i].key] = JSON.parse(
            responseConfigs?.data[i].value
          );
          continue;
        }
        tmpConfig[responseConfigs?.data[i].key] =
          responseConfigs?.data[i].value;
      }
      setLocalConfig(tmpConfig);
    }
  }, [responseConfigs]);

  if (currentUserState.currentUser === undefined) {
    return <Navigate to="/" replace />;
  }

  // OverLimit algoritm (clear | hide)
  // admin account (MobileApp)
  // list supported versions
  // link 1 for update app
  // link 2 for update app

  return (
    <div className={styles.main}>
      <div className={styles.minHeight40}>
        {isLoadingConfigs && <h4>wait...</h4>}
      </div>
      {errorConfigs && (
        <div>
          <h2>Error</h2>
        </div>
      )}
      <div className={styles.tree}>
        <div className={styles.lineNotActive}>
          Configuration
          <div
            className={styles.lineNotActive}
            style={{
              marginLeft: 20,
            }}
          >
            <div className={styles.forValue}>
              <div>Admin account (email)</div>
              <div className={styles.overDescription}>
                <input
                  placeholder={'email@domain.com'}
                  type="email"
                  className={styles.smalFormulaTextarea}
                  value={localConfig?.mobileAppAdmin || ''}
                  onChange={(e: any) => {
                    setLocalConfig((prevState) => {
                      return {
                        ...prevState,
                        mobileAppAdmin: e.target.value,
                      };
                    });
                  }}
                />
              </div>
            </div>
            <div className={styles.descriptionContent}>
              Can see collections that are in test mode in the mobile
              application
            </div>
          </div>
          <div
            className={styles.lineNotActive}
            style={{
              marginLeft: 20,
            }}
          >
            <div className={styles.forValue}>
              <div>(iOS) Supported versions</div>
              <div className={styles.overDescription}>
                <input
                  placeholder={'1.2.3'}
                  type="text"
                  className={styles.smalFormulaTextarea}
                  value={tmpVersion}
                  onChange={(e: any) => {
                    setTmpVersion(e.target.value);
                  }}
                />
                <span
                  className={styles.addVersionBtn}
                  onClick={() => {
                    if (
                      localConfig?.supportedVersions === undefined ||
                      !localConfig?.supportedVersions
                    ) {
                      setLocalConfig((prevState) => {
                        return {
                          ...prevState,
                          supportedVersions: [tmpVersion],
                        };
                      });
                    } else {
                      setLocalConfig((prevState) => {
                        return {
                          ...prevState,
                          supportedVersions: [
                            ...prevState.supportedVersions,
                            tmpVersion,
                          ],
                        };
                      });
                    }
                    setTmpVersion('');
                  }}
                >
                  Add
                </span>
              </div>
            </div>
            <div className={styles.descriptionContentRight}>
              {localConfig?.supportedVersions !== undefined &&
                localConfig?.supportedVersions &&
                localConfig?.supportedVersions.map((el: any) => {
                  return (
                    <span
                      key={el}
                      className={styles.testModeActive}
                      onClick={() => {
                        const findIndex =
                          localConfig.supportedVersions.findIndex(
                            (x: IKeyVal) => x === el
                          );

                        const copied: Array<any> = [
                          ...localConfig.supportedVersions,
                        ];
                        const after = copied.splice(findIndex + 1);
                        const before = copied.splice(0, findIndex);
                        const upd = [...before, ...after];

                        setLocalConfig((prevState) => {
                          return {
                            ...prevState,
                            supportedVersions: upd,
                          };
                        });
                        setTmpVersion('');
                      }}
                    >
                      <span>{el}</span>
                      <span> &times;</span>
                    </span>
                  );
                })}
            </div>
          </div>
          <div
            className={styles.lineNotActive}
            style={{
              marginLeft: 20,
            }}
          >
            <div className={styles.forValue}>
              <div>(Android) Supported versions</div>
              <div className={styles.overDescription}>
                <input
                  placeholder={'1.2.3'}
                  type="text"
                  className={styles.smalFormulaTextarea}
                  value={tmpAndroidVersion}
                  onChange={(e: any) => {
                    setTmpAndroidVersion(e.target.value);
                  }}
                />
                <span
                  className={styles.addVersionBtn}
                  onClick={() => {
                    if (
                      localConfig?.supportedAndroidVersions === undefined ||
                      !localConfig?.supportedAndroidVersions
                    ) {
                      setLocalConfig((prevState) => {
                        return {
                          ...prevState,
                          supportedAndroidVersions: [tmpAndroidVersion],
                        };
                      });
                    } else {
                      setLocalConfig((prevState) => {
                        return {
                          ...prevState,
                          supportedAndroidVersions: [
                            ...prevState.supportedAndroidVersions,
                            tmpAndroidVersion,
                          ],
                        };
                      });
                    }
                    setTmpAndroidVersion('');
                  }}
                >
                  Add
                </span>
              </div>
            </div>
            <div className={styles.descriptionContentRight}>
              {localConfig?.supportedAndroidVersions !== undefined &&
                localConfig?.supportedAndroidVersions &&
                localConfig?.supportedAndroidVersions?.map((el: any) => {
                  return (
                    <span
                      key={el}
                      className={styles.testModeActive}
                      onClick={() => {
                        const findIndex =
                          localConfig.supportedAndroidVersions.findIndex(
                            (x: IKeyVal) => x === el
                          );

                        const copied: Array<any> = [
                          ...localConfig.supportedAndroidVersions,
                        ];
                        const after = copied.splice(findIndex + 1);
                        const before = copied.splice(0, findIndex);
                        const upd = [...before, ...after];

                        setLocalConfig((prevState) => {
                          return {
                            ...prevState,
                            supportedAndroidVersions: upd,
                          };
                        });
                        setTmpAndroidVersion('');
                      }}
                    >
                      <span>{el}</span>
                      <span> &times;</span>
                    </span>
                  );
                })}
            </div>
          </div>
          <div
            className={styles.lineNotActive}
            style={{
              marginLeft: 20,
            }}
          >
            <div className={styles.forValue}>
              <div>(iOS) Update link (itms-apps)</div>
              <div className={styles.overDescription}>
                <input
                  placeholder={'itms-apps://...'}
                  type="text"
                  className={styles.smalFormulaTextarea}
                  value={localConfig?.itmsAppsUpdAppLink || ''}
                  onChange={(e: any) => {
                    setLocalConfig((prevState) => {
                      return {
                        ...prevState,
                        itmsAppsUpdAppLink: e.target.value,
                      };
                    });
                  }}
                />
              </div>
            </div>
            <div className={styles.descriptionContent}>
              Link to update the mobile application
            </div>
          </div>
          <div
            className={styles.lineNotActive}
            style={{
              marginLeft: 20,
            }}
          >
            <div className={styles.forValue}>
              <div>(iOS) Update link (https)</div>
              <div className={styles.overDescription}>
                <input
                  placeholder={'https://...'}
                  type="text"
                  className={styles.smalFormulaTextarea}
                  value={localConfig?.httpsUpdAppLink || ''}
                  onChange={(e: any) => {
                    setLocalConfig((prevState) => {
                      return {
                        ...prevState,
                        httpsUpdAppLink: e.target.value,
                      };
                    });
                  }}
                />
              </div>
            </div>
            <div className={styles.descriptionContent}>
              Link to update the mobile application
            </div>
          </div>
          <div
            className={styles.lineNotActive}
            style={{
              marginLeft: 20,
            }}
          >
            <div className={styles.forValue}>
              <div>(Android) Update link (market)</div>
              <div className={styles.overDescription}>
                <input
                  placeholder={'market://...'}
                  type="text"
                  className={styles.smalFormulaTextarea}
                  value={localConfig?.marketAppsUpdAndroidAppLink || ''}
                  onChange={(e: any) => {
                    setLocalConfig((prevState) => {
                      return {
                        ...prevState,
                        marketAppsUpdAndroidAppLink: e.target.value,
                      };
                    });
                  }}
                />
              </div>
            </div>
            <div className={styles.descriptionContent}>
              Link to update the mobile application
            </div>
          </div>
          <div
            className={styles.lineNotActive}
            style={{
              marginLeft: 20,
            }}
          >
            <div className={styles.forValue}>
              <div>(Android) Update link (https)</div>
              <div className={styles.overDescription}>
                <input
                  placeholder={'https://...'}
                  type="text"
                  className={styles.smalFormulaTextarea}
                  value={localConfig?.httpsUpdAndroidAppLink || ''}
                  onChange={(e: any) => {
                    setLocalConfig((prevState) => {
                      return {
                        ...prevState,
                        httpsUpdAndroidAppLink: e.target.value,
                      };
                    });
                  }}
                />
              </div>
            </div>
            <div className={styles.descriptionContent}>
              Link to update the mobile application
            </div>
          </div>
        </div>
      </div>
      <div className={styles.overActions}>
        <div className={styles.left} />
        <div className={styles.right}>
          <span />
          <span
            className={styles.btnAction}
            onClick={() => {
              if (isLoadingUpdConfig) {
                return;
              }
              doFetchUpdConfig({
                method: 'POST',
                data: {
                  configs: localConfig,
                },
              });
            }}
          >
            {errorUpdConfig ? 'Error' : isLoadingUpdConfig ? 'Wait...' : 'Save'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ConfigPage;
