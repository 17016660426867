import React, { useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { CurrentUserContext } from '../context/currentUser';
import styles from './Logs.module.scss';
import SummaryComponent from '../components/tools/Summary';
import AuthLogsComponent from '../components/logs/AuthLogs';
import CycleLogsComponent from '../components/logs/CycleLogs';

const LogsPage = (): JSX.Element => {
  const [currentUserState] = useContext(CurrentUserContext);

  // Retool: auth | dataProviderCycle

  const [screen, setScreen] = useState<string>('auth');

  if (currentUserState.currentUser === undefined) {
    return <Navigate to="/" replace />;
  }

  // main field (short name) | sort | limit | notify
  // other fields (short name)
  // collection name | collection description
  return (
    <div className={styles.base}>
      <div className={styles.content}>
        {screen === 'auth' && <AuthLogsComponent />}
        {screen === 'dataProviderCycle' && <CycleLogsComponent />}
      </div>
      <div className={styles.menuBlock}>
        <div
          className={styles.noActive}
          style={{
            paddingLeft: 10,
          }}
        >
          Logs
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor: screen === 'auth' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('auth')}
        >
          Auth
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor:
              screen === 'dataProviderCycle' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('dataProviderCycle')}
        >
          DataProviderCycle
        </div>
      </div>
    </div>
  );
};

export default LogsPage;
