import React, { useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { CurrentUserContext } from '../context/currentUser';
import styles from './Tools.module.scss';
import SummaryComponent from '../components/tools/Summary';

const ToolsPage = (): JSX.Element => {
  const [currentUserState] = useContext(CurrentUserContext);

  // Retool: summary

  const [screen, setScreen] = useState<string>('summary');

  if (currentUserState.currentUser === undefined) {
    return <Navigate to="/" replace />;
  }

  // main field (short name) | sort | limit | notify
  // other fields (short name)
  // collection name | collection description
  return (
    <div className={styles.base}>
      <div className={styles.content}>
        {screen === 'summary' && <SummaryComponent />}
        {/* {screen === 'create' && <CollectionCreateComponent />} */}
      </div>
      <div className={styles.menuBlock}>
        <div
          className={styles.noActive}
          style={{
            paddingLeft: 10,
          }}
        >
          Retool
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor: screen === 'summary' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('summary')}
        >
          Summary
        </div>
        {/* <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor: screen === 'create' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('create')}>
          Create
        </div> */}
      </div>
    </div>
  );
};

export default ToolsPage;
