import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { CurrentUserContext } from '../../context/currentUser';
import styles from './CycleLogs.module.scss';
import { API_GET_DATA_PROVIDER_LOGS } from '../../config';
import useFetch from '../../hooks/useFetch';
import { IKeyVal } from '../../interfaces';

const CycleLogsComponent = () => {
  const [currentUserState] = useContext(CurrentUserContext);

  const [logs, setLogs] = useState<null | Array<any>>(null);
  const [activeLog, setActiveLog] = useState<null | number>(null);

  const [
    { isLoading: isLoadingLogs, response: responseLogs, error: errorLogs },
    doFetchLogs,
  ] = useFetch(API_GET_DATA_PROVIDER_LOGS);

  useEffect(() => {
    doFetchLogs({
      method: 'POST',
      data: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (responseLogs) {
      setLogs(_.orderBy(responseLogs?.data, ['ttamp'], ['desc']));
    }
  }, [responseLogs]);

  if (currentUserState.currentUser === undefined) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className={styles.main}>
      {isLoadingLogs && (
        <div className={styles.minHeight40}>
          <h4>wait...</h4>
        </div>
      )}
      {errorLogs && (
        <div>
          <h2>Error</h2>
        </div>
      )}
      {logs !== null && logs?.length === 0 && (
        <div>
          <h2>Empty log list</h2>
        </div>
      )}
      {logs !== null && logs?.length > 0 && (
        <div className={styles.tree}>
          <div className={styles.lineNotActive}>
            {logs.map((el: any, idx: number) => {
              const dailyProps: IKeyVal = {};
              const tmpDailyProps: IKeyVal = {
                updStatisticsTime: 0,
                updStatisticsCount: 0,
                updEarningsEstimateTime: 0,
                updEarningsEstimateCount: 0,
                updGrowthEstimatesTime: 0,
                updGrowthEstimatesCount: 0,
                updPriceTargetTime: 0,
                updPriceTargetCount: 0,
                updIncomeStatementTime: 0,
                updIncomeStatementCount: 0,
                updDivTime: 0,
                updDivCount: 0,
                updEODTime: 0,
                updEODCount: 0,
                updProfileTime: 0,
                updProfileCount: 0,
              };

              if (el?.type === 'daily') {
                // CREATE PROPS
                // started
                // cycle time
                // manualCount
                // twelvedataCount
                // parsed
                // not parsed

                // ONE | TWO
                // avgFunc
                // countErrors

                let parsed = 0;
                let notParsed = 0;

                const avgFuncTime = (funcPoint: IKeyVal) => {
                  switch (funcPoint.funcName) {
                    case 'updStatistics':
                      tmpDailyProps.updStatisticsTime +=
                        funcPoint.finish - funcPoint.start;
                      tmpDailyProps.updStatisticsCount++;
                      break;
                    case 'updEarningsEstimate':
                      tmpDailyProps.updEarningsEstimateTime +=
                        funcPoint.finish - funcPoint.start;
                      tmpDailyProps.updEarningsEstimateCount++;
                      break;
                    case 'updGrowthEstimates':
                      tmpDailyProps.updGrowthEstimatesTime +=
                        funcPoint.finish - funcPoint.start;
                      tmpDailyProps.updGrowthEstimatesCount++;
                      break;
                    case 'updPriceTarget':
                      tmpDailyProps.updPriceTargetTime +=
                        funcPoint.finish - funcPoint.start;
                      tmpDailyProps.updPriceTargetCount++;
                      break;
                    case 'updIncomeStatement':
                      tmpDailyProps.updIncomeStatementTime +=
                        funcPoint.finish - funcPoint.start;
                      tmpDailyProps.updIncomeStatementCount++;
                      break;
                    case 'updDiv':
                      tmpDailyProps.updDivTime +=
                        funcPoint.finish - funcPoint.start;
                      tmpDailyProps.updDivCount++;
                      break;
                    case 'updEOD':
                      tmpDailyProps.updEODTime +=
                        funcPoint.finish - funcPoint.start;
                      tmpDailyProps.updEODCount++;
                      break;
                    case 'updProfile':
                      tmpDailyProps.updProfileTime +=
                        funcPoint.finish - funcPoint.start;
                      tmpDailyProps.updProfileCount++;
                      break;
                    default:
                    //
                  }
                };

                for (let i = 0; i < el.cycleZero.length; i++) {
                  const act = el.cycleZero[i];
                  for (let j = 0; j < act.updSteps.length; j++) {
                    avgFuncTime(act.updSteps[j]);
                  }
                }

                for (let i = 0; i < el.cycleOne.length; i++) {
                  const act = el.cycleOne[i];
                  for (let j = 0; j < act.updSteps.length; j++) {
                    if (act.updSteps[j].error !== null) {
                      avgFuncTime(act.updSteps[j]);
                      notParsed++;
                      continue;
                    }
                  }
                }

                parsed = el.cycleZero.length - notParsed;

                dailyProps.started =
                  moment(el.start).format('MMMM Do YYYY, h:mm:ss a') +
                  ' (' +
                  moment(el.start).fromNow() +
                  ')';
                dailyProps.cycleTime =
                  Math.round((el.finish - el.start) / 60000) + ' min.';
                dailyProps.manualCount = el.manualCount;
                dailyProps.twelvedataCount = el.twelvedataCount;
                dailyProps.parsed = parsed;
                dailyProps.notParsed = notParsed;
              }

              if (activeLog !== null && activeLog === el?.ttamp) {
                return (
                  <div key={el?.ttamp + '_____' + idx}>
                    {el?.type === 'daily' && (
                      <div>
                        <table>
                          {/* <thead>
                            <tr>
                              <th>ticker</th>
                              <th>ticker</th>
                            </tr>
                          </thead> */}
                          <tbody>
                            <tr>
                              <th>started</th>
                              <th>{dailyProps.started}</th>
                            </tr>
                            <tr>
                              <th>cycleTime</th>
                              <th>{dailyProps.cycleTime}</th>
                            </tr>
                            <tr>
                              <th>manualCount</th>
                              <th>{dailyProps.manualCount}</th>
                            </tr>
                            <tr>
                              <th>twelvedataCount</th>
                              <th>{dailyProps.twelvedataCount}</th>
                            </tr>
                            <tr>
                              <th>parsed</th>
                              <th>{dailyProps.parsed}</th>
                            </tr>
                            <tr>
                              <th>notParsed</th>
                              <th>{dailyProps.notParsed}</th>
                            </tr>
                          </tbody>
                        </table>
                        <table>
                          <thead>
                            <tr>
                              <th>func</th>
                              <th>avg. time</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th>updStatistics</th>
                              <th>
                                {Math.round(
                                  tmpDailyProps.updStatisticsTime /
                                    tmpDailyProps.updStatisticsCount /
                                    10
                                ) /
                                  100 +
                                  ' sec.'}
                              </th>
                            </tr>
                            <tr>
                              <th>updEarningsEstimate</th>
                              <th>
                                {Math.round(
                                  tmpDailyProps.updEarningsEstimateTime /
                                    tmpDailyProps.updEarningsEstimateCount /
                                    10
                                ) /
                                  100 +
                                  ' sec.'}
                              </th>
                            </tr>
                            <tr>
                              <th>updGrowthEstimates</th>
                              <th>
                                {Math.round(
                                  tmpDailyProps.updGrowthEstimatesTime /
                                    tmpDailyProps.updGrowthEstimatesCount /
                                    10
                                ) /
                                  100 +
                                  ' sec.'}
                              </th>
                            </tr>
                            <tr>
                              <th>updPriceTarget</th>
                              <th>
                                {Math.round(
                                  tmpDailyProps.updPriceTargetTime /
                                    tmpDailyProps.updPriceTargetCount /
                                    10
                                ) /
                                  100 +
                                  ' sec.'}
                              </th>
                            </tr>
                            <tr>
                              <th>updIncomeStatement</th>
                              <th>
                                {Math.round(
                                  tmpDailyProps.updIncomeStatementTime /
                                    tmpDailyProps.updIncomeStatementCount /
                                    10
                                ) /
                                  100 +
                                  ' sec.'}
                              </th>
                            </tr>
                            <tr>
                              <th>updDiv</th>
                              <th>
                                {Math.round(
                                  tmpDailyProps.updDivTime /
                                    tmpDailyProps.updDivCount /
                                    10
                                ) /
                                  100 +
                                  ' sec.'}
                              </th>
                            </tr>
                            <tr>
                              <th>updEOD</th>
                              <th>
                                {Math.round(
                                  tmpDailyProps.updEODTime /
                                    tmpDailyProps.updEODCount /
                                    10
                                ) /
                                  100 +
                                  ' sec.'}
                              </th>
                            </tr>
                            <tr>
                              <th>updProfile</th>
                              <th>
                                {Math.round(
                                  tmpDailyProps.updProfileTime /
                                    tmpDailyProps.updProfileCount /
                                    10
                                ) /
                                  100 +
                                  ' sec.'}
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                    <div
                      className={styles.lineNotActive}
                      style={{
                        marginLeft: 20,
                      }}
                      key={el?.start + '_' + idx}
                    >
                      <span
                        onClick={() => setActiveLog(null)}
                        className={styles.toggleHidden}
                      >
                        {'↑'}
                      </span>
                      {el?.type} ({moment.utc(el?.start).fromNow()})
                      <pre>{JSON.stringify(el, null, 4)}</pre>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div key={el?.ttamp + '___' + idx}>
                    {el?.type === 'daily' && (
                      <div>
                        <table>
                          {/* <thead>
                            <tr>
                              <th>ticker</th>
                              <th>ticker</th>
                            </tr>
                          </thead> */}
                          <tbody>
                            <tr>
                              <th>started</th>
                              <th>{dailyProps.started}</th>
                            </tr>
                            <tr>
                              <th>cycleTime</th>
                              <th>{dailyProps.cycleTime}</th>
                            </tr>
                            <tr>
                              <th>manualCount</th>
                              <th>{dailyProps.manualCount}</th>
                            </tr>
                            <tr>
                              <th>twelvedataCount</th>
                              <th>{dailyProps.twelvedataCount}</th>
                            </tr>
                            <tr>
                              <th>parsed</th>
                              <th>{dailyProps.parsed}</th>
                            </tr>
                            <tr>
                              <th>notParsed</th>
                              <th>{dailyProps.notParsed}</th>
                            </tr>
                          </tbody>
                        </table>
                        <table>
                          <thead>
                            <tr>
                              <th>func</th>
                              <th>avg. time</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th>updStatistics</th>
                              <th>
                                {Math.round(
                                  tmpDailyProps.updStatisticsTime /
                                    tmpDailyProps.updStatisticsCount /
                                    10
                                ) /
                                  100 +
                                  ' sec.'}
                              </th>
                            </tr>
                            <tr>
                              <th>updEarningsEstimate</th>
                              <th>
                                {Math.round(
                                  tmpDailyProps.updEarningsEstimateTime /
                                    tmpDailyProps.updEarningsEstimateCount /
                                    10
                                ) /
                                  100 +
                                  ' sec.'}
                              </th>
                            </tr>
                            <tr>
                              <th>updGrowthEstimates</th>
                              <th>
                                {Math.round(
                                  tmpDailyProps.updGrowthEstimatesTime /
                                    tmpDailyProps.updGrowthEstimatesCount /
                                    10
                                ) /
                                  100 +
                                  ' sec.'}
                              </th>
                            </tr>
                            <tr>
                              <th>updPriceTarget</th>
                              <th>
                                {Math.round(
                                  tmpDailyProps.updPriceTargetTime /
                                    tmpDailyProps.updPriceTargetCount /
                                    10
                                ) /
                                  100 +
                                  ' sec.'}
                              </th>
                            </tr>
                            <tr>
                              <th>updIncomeStatement</th>
                              <th>
                                {Math.round(
                                  tmpDailyProps.updIncomeStatementTime /
                                    tmpDailyProps.updIncomeStatementCount /
                                    10
                                ) /
                                  100 +
                                  ' sec.'}
                              </th>
                            </tr>
                            <tr>
                              <th>updDiv</th>
                              <th>
                                {Math.round(
                                  tmpDailyProps.updDivTime /
                                    tmpDailyProps.updDivCount /
                                    10
                                ) /
                                  100 +
                                  ' sec.'}
                              </th>
                            </tr>
                            <tr>
                              <th>updEOD</th>
                              <th>
                                {Math.round(
                                  tmpDailyProps.updEODTime /
                                    tmpDailyProps.updEODCount /
                                    10
                                ) /
                                  100 +
                                  ' sec.'}
                              </th>
                            </tr>
                            <tr>
                              <th>updProfile</th>
                              <th>
                                {Math.round(
                                  tmpDailyProps.updProfileTime /
                                    tmpDailyProps.updProfileCount /
                                    10
                                ) /
                                  100 +
                                  ' sec.'}
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                    <div
                      className={styles.lineNotActive}
                      style={{
                        marginLeft: 20,
                      }}
                      onClick={() => setActiveLog(el?.start)}
                      key={el?.start + '__' + idx}
                    >
                      <span className={styles.toggleHidden}>{'↓'}</span>
                      {el?.type} ({moment.utc(el?.start).fromNow()})
                      <div
                        className={styles.lineNotActive}
                        style={{
                          marginLeft: 20,
                        }}
                      />
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default CycleLogsComponent;
