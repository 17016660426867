import React, {useContext} from 'react';
import {NavLink} from 'react-router-dom';
import {CurrentUserContext} from '../context/currentUser';
import styles from './Layout.module.scss';

const Layout = ({children}: any): JSX.Element => {
  const [currentUserState] = useContext(CurrentUserContext);

  // const dispatch = useDispatch();

  // const listProviders = useSelector((state: any) => state.providers.providers);
  // const activeProvider = useSelector((state: any) => state.provider.provider);

  return (
    <div className={styles.main}>
      {!currentUserState.isLoading && (
        <>
          <div className={styles.topMenu}>
            <div>
              <NavLink to="/">HOME</NavLink>
            </div>
            <div>
              {currentUserState.isLoggedIn === true && (
                <>
                  {/* {listProviders &&
                    listProviders.map((el: any) => {
                      if (activeProvider && activeProvider === el) {
                        return (
                          <span
                            key={el}
                            className={styles.activeProvider}
                            onClick={() => {
                              dispatch(setProvider(el));
                            }}>
                            {el}
                          </span>
                        );
                      } else {
                        return (
                          <span
                            key={el}
                            className={styles.notActiveProvider}
                            onClick={() => {
                              dispatch(setProvider(el));
                            }}>
                            {el}
                          </span>
                        );
                      }
                    })} */}
                  <NavLink to="/schema">Schema</NavLink>
                  <NavLink to="/collections">Collections</NavLink>
                  <NavLink to="/indicators">Indicators</NavLink>
                  <NavLink to="/list">Stock list</NavLink>
                  {/* <NavLink to="/import">Import</NavLink> */}
                  <NavLink to="/users">Users</NavLink>
                  <NavLink to="/config">Config</NavLink>
                  <NavLink to="/tools">Tools</NavLink>
                  {/* <NavLink to="/admins">Admins</NavLink> */}
                  <NavLink to="/logs">Logs</NavLink>
                </>
              )}
            </div>
            <div>
              {currentUserState.isLoggedIn === true && (
                <>
                  <NavLink to="/logout">Logout</NavLink>
                </>
              )}
              {currentUserState.isLoggedIn === false && (
                <>
                  <NavLink to="/signin">SignIn</NavLink>
                </>
              )}
            </div>
          </div>
          {children}
        </>
      )}
    </div>
  );
};

export default Layout;
