import React, { useState } from 'react';
import styles from './CollectionCreate.module.scss';
import CollectionCreateEdit from './CollectionCreateEdit';

const CollectionCreateComponent = (): JSX.Element => {
  const [showCreateCollection, setShowCreateCollection] =
    useState<boolean>(false);

  return (
    <div className={styles.main}>
      <div className={styles.overTestBlock}>
        <div>
          <h2>How to create new collection?</h2>
          <ul>
            <li>
              <ul>
                <li>
                  slug - unique collection identifier. not be shown to users.
                </li>
                <li>
                  logo - optional. you can add a link to the collection logo.
                </li>
                <li>name - be shown in the mobile app.</li>
                <li>description - be shown in the mobile app.</li>
              </ul>
            </li>
            <li>
              Main field - field that will be used to form collection list
            </li>
            <li>
              Second field, Third field, Fourth field - optional fields. these
              fields will be shown in the mobile app if not empty.
            </li>
            <li>
              First filter, Second filter - fields for filtering the collection.
              Boolead AND rule. Exclusion takes precedence over inclusion.
            </li>
          </ul>
        </div>
        <div />
      </div>
      <div className={styles.textRight}>
        <span
          className={styles.testBlock}
          onClick={() => {
            // set all default values
            setShowCreateCollection(true);
          }}
        >
          CREATE COLLECTION
        </span>
      </div>

      {showCreateCollection && (
        <CollectionCreateEdit
          setShowCreateCollection={setShowCreateCollection}
        />
      )}
    </div>
  );
};

export default CollectionCreateComponent;
