import React from 'react';
import styles from './SummaryModal.module.scss';

const SummaryModalComponent = ({
  setShowTable,
  isLoadingSummary,
  responseSummary,
  errorSummary,
}: any): JSX.Element => {
  const propsList = [
    'id',
    'symbol_english',
    'price_current',
    'stat_52_wl',
    'stat_52_wh',
    'stat_52_w_change',
    'beta',
    'outstanding_shares',
    'float_shares',
    'revenue',
    'earnings',
    'debt',
    'cash',
    'cash_flow_from_operations',
    'free_cashflow',
    'eps',
    'bv',
    'cr',
    'de',
    'roe',
    'roa',
    'pe_trailing',
    'pe_forward',
    'peg',
    'pbv',
    'profit_margin',
    'operating_margin',
    'ps',
    'rps',
    'dps_currency',
    'dps_percent',
    'payout',
    'cash_per_share',
    'market_cap',
    'last_dividends_date',
    'last_dividends_ex_date',
    'dps_5_y',
    'price_target_median',
    'forward_dps_percent',
    'forward_dps_currency',
    'mrq',
    'gross_profit',
    'insiders',
    'institution',
    'last_split_factor',
    'last_split_date',
    'eps_estimate_0_date',
    'eps_period_0',
    'eps_number_of_analysts_0',
    'eps_avg_estimate_0',
    'eps_year_ago_eps_0',
    'eps_estimate_1_date',
    'eps_period_1',
    'eps_number_of_analysts_1',
    'eps_avg_estimate_1',
    'eps_year_ago_eps_1',
    'website',
    'description',
    'updated_at',
  ];
  return (
    <div className={styles.main}>
      <div
        className={styles.close}
        onClick={() => {
          setShowTable(false);
        }}
      >
        X
      </div>
      <div className={styles.contentBlocks}>
        <h3>
          {isLoadingSummary ? 'WAIT...' : ''}
          {errorSummary ? 'ERROR' : ''}
        </h3>
        {!isLoadingSummary &&
          !errorSummary &&
          responseSummary &&
          responseSummary.data && (
            <div className={styles.overTable}>
              <h2>TABLE</h2>
              <table>
                <thead>
                  <tr>
                    {propsList.map((el: any, idx: number) => {
                      return <th key={el + '_____' + idx}>{el}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {responseSummary.data.map((el: any, idx: number) => {
                    return (
                      <tr key={el.id + '__' + idx}>
                        {propsList.map((prop: any, _idx: number) => {
                          return (
                            <th key={el[prop] + '_' + idx + '_' + _idx}>
                              {el[prop]}
                            </th>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
      </div>
    </div>
  );
};

export default SummaryModalComponent;
