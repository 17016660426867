import React from 'react';
import {Routes, Route} from 'react-router-dom';
import Layout from './hoc/Layout';
import CollectionsPage from './pages/Collections';
import HomePage from './pages/Home';
import ListPage from './pages/List';
import LogoutPage from './pages/Logout';
import NotFoundPage from './pages/NotFound';
import SchemaPage from './pages/Schema';
import SignInPage from './pages/SignIn';
import UsersPage from './pages/Users';
import IndicatorsPage from './pages/Indicators';
import ConfigPage from './pages/Config';
import LogsPage from './pages/Logs';
import ToolsPage from './pages/Tools';

const AppRoutes = (): JSX.Element => {
  return (
    <Layout>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/signin' element={<SignInPage />} />
        <Route path='/indicators' element={<IndicatorsPage />} />
        {/* <Route path="/admins" element={<AdminsPage />} /> */}
        <Route path='/users' element={<UsersPage />} />
        <Route path='/config' element={<ConfigPage />} />
        {/* <Route path="/import" element={<ImportPage />} /> */}
        <Route path='/collections' element={<CollectionsPage />} />
        <Route path='/schema' element={<SchemaPage />} />
        <Route path='/list' element={<ListPage />} />
        <Route path='/tools' element={<ToolsPage />} />
        <Route path='/logs' element={<LogsPage />} />
        <Route path='/logout' element={<LogoutPage />} />
        <Route path='/*' element={<NotFoundPage />} />
      </Routes>
    </Layout>
  );
};

export default AppRoutes;
